.App {
  text-align: center;
  font-family: "Open Sans", sans-serif;
}

h1 {
  font-size: 45px;
  font-weight: bold;
  line-height: 120%;
  color: #ffffff;
  margin: 10px;
}

h2 {
  font-size: 25px;
  line-height: 30px;
  color: #26c17e;
  margin: 40px auto 40px 0px;
}

h3 {
  font-size: 16px;
}

p {
  display: inline;
}

button {
  color: #ffffff;
  background-color: #222935;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #26c17e;
}

button:disabled {
  background-color: #667081;
  color: #222935;
  cursor: auto;
}

ul {
  padding-left: 0;
}

li {
  list-style-type: none;
}

.hidden {
  display: none;
}

.starting_page {
  background-color: #000000;
  background: linear-gradient(
      to top,
      rgba(12, 12, 12, 0.7),
      rgba(12, 12, 12, 0.7)
    ),
    url(../images/image\ 2.jpg) no-repeat center/cover;
  height: 100vh;
}

.starting_page__avatar {
  margin-top: 124px;
  width: 163px;
  height: 163px;
  border-radius: 50%;
  border-width: 1px;
  border-color: #ffffff;
}

.starting_page__subtitle {
  color: #ffffff;
  font-size: 25px;
  font-weight: 600;
  margin: 15px;
}

.starting_page__container {
  max-width: 500px;
}

.starting_page__motto {
  color: #ffffff;
  font-size: 14px;
  margin: 15px;
}

.starting_page__button {
  margin-bottom: 217px;
  margin-top: 15px;
  border-radius: 5px;
  height: 40px;
  width: 127px;
  cursor: pointer;
}

.main_page {
  display: inline-flex;
}

.panel {
  background-color: #222935;
  position: sticky;
  top: 0;
  height: 100vh;
  margin-left: -250px;
  flex: 0 0 250px;
  transition: all 0.5s;
}

.panel--hidden {
  margin-left: 0;
}

.menu {
  width: 250px;
  background-color: #222935;
  font-size: 16px;
}

.menu__photobox {
  height: 200px;
}

.menu__avatar {
  height: 100px;
  width: 100px;
  margin-top: 20px;
  border-radius: 50%;
  border-color: #ffffff;
  border: 1px;
}

.menu__header {
  color: #ffffff;
}

.menu__list {
  text-align: left;
  padding-left: 0;
}

.menu__item {
  list-style-type: none;
  height: 60px;
}

.menu__pictogram {
  margin: 0;
  padding: 0;
  width: 54px;
  text-align: center;
}

.menu__link {
  text-decoration: none;
  color: #667081;
  cursor: pointer;
}

.menu__link:hover,
.active {
  color: #26c17e;
}

.menu__button--back {
  width: 130px;
  border-radius: 5px;
  height: 30px;
  color: #667081;
  bottom: 15px;
  left: 50px;
  position: absolute;
}

.menu__button--back:hover,
.menu__button--back:hover.p {
  color: #ffffff;
}

.menu_back__text {
  margin-left: 10px;
}

.burger {
  width: 30px;
  height: 30px;
  top: 30px;
  border-radius: 0 5px 5px 0;
  font-size: 12px;
  cursor: pointer;
  flex: 0 0 30px;
  position: sticky;
}

.burger:hover {
  background-color: #222935;
}

.main_content {
  text-align: left;
  padding-right: 36px;
  padding-left: 36px;
}

.education__timeline {
  overflow-y: hidden;
  min-height: 30vh;
  max-height: 70vh;
  padding-right: 5px;
}

.education__timeline:hover {
  overflow-y: scroll;
  padding-right: 0;
}

.education__timeline:hover::-webkit-scrollbar {
  background-color: #ffffff;
  width: 5px;
}

.education__timeline:hover::-webkit-scrollbar-thumb {
  background-color: #222935;
  border-radius: 2.5px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.education--loading {
  height: 30vh;
  overflow: hidden;
}

.education--fail {
  height: 30vh;
  color: red;
  text-align: center;
  align-content: center;
  overflow: hidden;
  display: table;
  width: 100%;
}

.education--fail p {
  display: table-cell;
  vertical-align: middle;
}

.loading_emblem {
  display: block;
  animation: 1s linear 0s normal none infinite running rotation;
  color: #26c17e;
  margin: 150px auto;
}

.education_element {
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.education_element__left {
  flex: 0 0 auto;
}

.education_element__year {
  margin: 12px 0;
  display: block;
}

.education_element__vertical_stripe {
  width: 5px;
  background-color: #26c17e;
  height: calc(100% - 35px);
  margin: auto;
}

.education_element__triangle {
  flex: 0 0 36px;
}

.education_element__triangle img {
  display: block;
  margin: 15px 0 auto auto;
}

.education_element__container {
  background-color: #eeeeee;
  padding: 15px;
  flex: 1 1 100%;
}

.education_element__title {
  font-weight: bold;
  display: block;
}

.experience_element {
  display: inline-flex;
  margin-top: 10px;
}

.experience_element__left {
  flex: 0 0 120px;
}

.experience_element__company,
.experience_element__job {
  font-weight: bold;
}

.experience_element__year,
.experience_element__description {
  display: block;
  margin: 10px auto;
}

.skills__header {
  display: inline-flex;
  width: 100%;
}

.skills__title {
  flex: 1 1 auto;
}

.skills__button--edit {
  flex: 0 0 auto;
  height: 40px;
  width: 140px;
  margin: 40px auto;
}

.skills_element {
  background-color: #26c17e;
  margin: 5px;
  color: #ffffff;
}

.skills_element__label {
  margin: 5px auto 5px 15px;
  line-height: 30px;
}

.skills__scale {
  background-image: url(../images/scale.PNG);
  width: 100%;
  height: 110px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.skills__form {
  height: 200px;
  margin: 0 auto 30px auto;
  border: 1px solid #26c17e;
  border-radius: 10px;
  padding: 15px;
  position: relative;
}

.skills__form_element {
  height: 70px;
}

.skills__form--input {
  height: 40px;
  width: 270px;
  border-radius: 5px;
}

.skills__form--invalid_input {
  height: 40px;
  width: 270px;
  border-radius: 5px;
  color: red;
  border-color: red;
}

.skills__form--label {
  display: inline-block;
  width: 150px;
}

.skills__form--submit {
  height: 40px;
  width: 100px;
  position: absolute;
  bottom: 20px;
}

.skills__form--error {
  color: red;
  font-size: 12px;
  display: block;
  margin: 2px;
}

.portfolio__navigation_item {
  cursor: pointer;
}

.portfolio__navigation_item--active {
  color: #26c17e;
}

.portfolio__element {
  width: 308px;
  height: 190px;
  overflow: hidden;
}

@keyframes overlap {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-194px);
  }
}

.filter-item {
  margin: 10px;
}

.slider {
  width: 308px;
  height: 195px;
  padding: 15px;
  box-sizing: border-box;
  background: linear-gradient(
    to top,
    rgba(243, 243, 243, 0.9),
    rgba(243, 243, 243, 0.9)
  );
}

.portfolio__element:hover .slider {
  animation: 0.5s ease-out 0s normal none running overlap;
  animation-fill-mode: forwards;
}

.slider__title {
  display: block;
  margin-top: 0;
  font-weight: bold;
  color: #26c17e;
}

.contacts__list {
  padding: 0;
}

.contacts__list_item {
  height: 38px;
  line-height: 19px;
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 10px auto;
}

.contacts__icon {
  font-size: 30px;
  flex: 0 0 40px;
  color: #26c17e;
  text-align: left;
}

.contacts__label {
  flex: 0 0 auto;
  margin-left: 20px;
}

.contacts__link {
  text-decoration: none;
  color: #000000;
}

.contacts__link:hover {
  text-decoration: underline;
}

.contacts__network_name {
  font-weight: bold;
  display: block;
  margin: 0;
}

.feedback_element__text_container {
  background-color: #eeeeee;
  padding: 15px;
}

.feedback_element__signature_container {
  display: flex;
  margin: 30px auto;
}

.feedback_element__img {
  height: 50px;
  flex: 0 0 50px;
  border-radius: 50%;
}

.feedback_element__signature {
  padding-left: 20px;
}

.feedback_element__signature p {
  display: block;
  font-size: 12px;
  line-height: 14.4px;
  margin: 2px auto;
}

.feedback_element__name {
  font-weight: bold;
}

.scroll {
  width: 30px;
  height: 30px;
  bottom: 0;
  left: calc(100% - 55px);
  border-radius: 5px 5px 0 0;
  font-size: 12px;
  cursor: pointer;
  position: sticky;
}

@media only screen and (max-width: 768px) {
  .panel {
    margin-left: -54px;
    flex: 0 0 54px;
  }
  .panel--hidden {
    margin-left: 0;
  }
  .menu {
    width: 54px;
  }
  .menu__avatar {
    height: 40px;
    width: 40px;
  }
  .menu__item--label,
  .menu__button--back p,
  .menu__header {
    display: none;
  }
  .menu__button--back {
    width: 40px;
    left: 6px;
  }
}
